import { Config } from "./config";

/**
 * Enable log
 * 
 */
export const enableLog = () => {
    Config.isLogEnabled = true;
};

