import { Util } from "./util";

/**
 *
 * supply sqlweb
 * @param {*} value
 */
export const useSqlWeb = (value) => {
    Util.sqlWeb = value;
};

